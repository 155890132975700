/*
| Developed by Starton
| Filename : DashboardDrawerMenu.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, Divider, List } from '@mui/material'
import { ProductUpdates } from '../ProductUpdates/ProductUpdates'
import { DashboardDrawerMenuItem } from './DashboardDrawerMenuItem'
import { useVersionReleaseNotes } from '@/services/fapi'
import { NavDefaultConfig, NavItem } from '@/config/common/nav.config'
import { useWhiteLabel } from '@/hooks/white-labelling/useWhiteLabel'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DashboardDrawerMenuProps {
	open: boolean
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardDrawerMenu: React.FC<DashboardDrawerMenuProps> = (props) => {
	const { open } = props
	const {
		featuredFlags: { enableListener, enableRelayer, enableRPCNode },
	} = useWhiteLabel()

	// SWR
	//--------------------------------------------------------------------------
	const { data } = useVersionReleaseNotes()

	// Memoization
	//--------------------------------------------------------------------------
	const versionReleaseNotesLength = React.useMemo(() => data?.data?.length ?? 0, [data])
	const navItemsEntries = React.useMemo(() => {
		// Filter nav items for exclude `watcher` is `enableListener` is false or `transactions` && `wallet` if `enableRelayer` is false
		return Object.entries(NavDefaultConfig.navItems)
			.filter(([key]) => {
				if (key === 'watcher') return enableListener
				// if (key === 'transactions' || key === 'wallet') return enableRelayer
				if (key === 'transactionManager') return enableRelayer
				if (key === 'rpcNode') return enableRPCNode
				return true
			})
			.map(([key, data]) => [key, data])
	}, [enableListener, enableRelayer, enableRPCNode])
	const bottomNavItemsEntries = React.useMemo(() => {
		return Object.entries(NavDefaultConfig.bottomNavItems)
	}, [])

	// Render
	//--------------------------------------------------------------------------
	return (
		<List component="nav">
			{navItemsEntries.map(([key, data]) => (
				<DashboardDrawerMenuItem key={key as string} data={data as NavItem} open={open} />
			))}
			<Divider sx={{ marginY: 2 }} />
			{bottomNavItemsEntries.map(([key, data]) => (
				<DashboardDrawerMenuItem key={key} data={data} open={open} />
			))}
			<Box>{versionReleaseNotesLength > 0 ? <ProductUpdates open={open} /> : null}</Box>
		</List>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
DashboardDrawerMenu.displayName = 'DashboardDrawerMenu'

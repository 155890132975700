/*
| Developed by Starton
| Filename : ThemeProvider.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import moment from 'moment'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import { StartonLightTheme, StartonDarkTheme } from '@starton/react-ui'
import { enUS, Localization } from '@mui/material/locale'
import CssBaseline from '@mui/material/CssBaseline'
import { AvailableLanguages } from '@/contracts'
import { useWhiteLabel } from '@/components/white-labelling/context/whitelabel.context'
import { Dictionary } from '@/utils'
import internalTheme from '@/styles/mui.theme'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ThemeProviderProps {
	children: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Localization array for connected Material UI with Next Translate
|--------------------------------------------------------------------------
*/
const connectedLanguages: Dictionary<Localization, AvailableLanguages> = {
	en: enUS,
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ThemeProvider: React.FC<ThemeProviderProps> = (props) => {
	const { lang } = useTranslation()
	const whiteLabel = useWhiteLabel()

	// MUI create theme with I18N changer and update moment languages
	// ----------------------------------------------------------------------------
	const mergedTheme = React.useMemo(() => {
		// Define locale for moment
		const locale: AvailableLanguages = (lang as AvailableLanguages) ?? 'en'
		moment().locale(locale)

		const currentTheme = whiteLabel.colorMode === 'light' ? StartonLightTheme : StartonDarkTheme

		return createTheme(
			deepmerge(
				{
					...currentTheme,
					typography: deepmerge(currentTheme.typography, internalTheme.typography),
					whiteLabelling: {
						isStarton: whiteLabel.isStarton,
						colorMode: whiteLabel.colorMode,
						logo: whiteLabel.logo,
						logoType: whiteLabel.logoType,
						name: whiteLabel.name,
						featuredFlags: whiteLabel.featuredFlags,
						homeVideos: whiteLabel.homeVideos,
						twitterPageUrl: whiteLabel.twitterPageUrl,
						slug: whiteLabel.slug,
					},
				},
				{
					...whiteLabel.theme,
				},
			),
			connectedLanguages?.[locale] ?? enUS,
		)
	}, [
		lang,
		whiteLabel.colorMode,
		whiteLabel.featuredFlags,
		whiteLabel.isStarton,
		whiteLabel.logo,
		whiteLabel.logoType,
		whiteLabel.name,
		whiteLabel.slug,
		whiteLabel.homeVideos,
		whiteLabel.twitterPageUrl,
		whiteLabel.theme,
	])

	return (
		<MuiThemeProvider theme={mergedTheme}>
			{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
			<CssBaseline />
			{/* Children */}
			{props.children}
		</MuiThemeProvider>
	)
}

/*
| Developed by Starton
| Filename : StartonUserMenu.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { DarkMode, ForumOutlined, LightMode, LoginOutlined, PersonOutlined } from '@mui/icons-material'
import { styled, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
	StartonAvatar,
	StartonButton,
	StartonButtonProps,
	StartonMenu,
	StartonMenuItem,
	StartonMenuItemTitle,
} from '@starton/react-ui'
import { usePostHog } from 'posthog-js/react'
import { useAppDispatch } from '../../../stores/hooks'
import { setOpenSupportDialog } from '../../../stores/common/commonSlice'
import { GtagService } from '@/services/gtag/gtag.service'
import { useWhoami } from '@/services/auth-api/endpoints/users'
import { authLogout } from '@/services/auth-api/endpoints/auth'
import { UrlsConfig } from '@/config/urls/urls.config'
import { useWhiteLabel } from '@/components/white-labelling/context/whitelabel.context'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonUserMenuProps {
	buttonProps?: StartonButtonProps
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ButtonStyled = styled(StartonButton)(({ theme }) => ({
	height: '100%',
	'&:hover': { backgroundColor: theme.palette.background.paper },
	transition: 'all 0.2s ease-in-out',
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonUserMenu: React.FC<StartonUserMenuProps> = (props) => {
	const { buttonProps } = props
	const { t } = useTranslation()
	const router = useRouter()
	const { projectId } = router.query
	const { data: user } = useWhoami()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const menuIsOpen = Boolean(anchorEl)
	const posthog = usePostHog()
	const dispatch = useAppDispatch()
	const whiteLabel = useWhiteLabel()

	// Memoization
	//--------------------------------------------------------------------------
	const avatarLetter = React.useMemo(() => {
		return user?.name ? user?.name[0] : ''
	}, [user?.name])

	const myProfileLink = React.useMemo(() => {
		const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : ''

		return origin ? `${UrlsConfig.auth.settings}?redirect_to=${origin}${router.asPath}` : UrlsConfig.auth.settings
	}, [router.asPath])

	// Methods
	// ----------------------------------------------------------------------------
	const openMenuOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleOpenSupport = () => {
		dispatch(setOpenSupportDialog(true))
	}

	const handleLogout = async () => {
		const payload = {
			...(user?.id && {
				userId: user.id,
			}),
			...(projectId && {
				projectId: String(projectId),
			}),
		}
		GtagService.createUserEvent(
			{
				name: 'user-logout',
				...payload,
			},
			posthog,
		)
		posthog.reset()
		try {
			await authLogout()
		} catch (e) {
			console.error(e)
		} finally {
			await router.push(UrlsConfig.auth.login)
		}
	}

	const handleColorMode = () => {
		whiteLabel.onUpdate({
			colorMode: whiteLabel.colorMode === 'light' ? 'dark' : 'light',
			isStarton: whiteLabel.isStarton,
			featuredFlags: whiteLabel.featuredFlags,
			homeVideos: whiteLabel.homeVideos,
			twitterPageUrl: whiteLabel.twitterPageUrl,
			logo: whiteLabel.logo,
			logoType: whiteLabel.logoType,
			name: whiteLabel.name,
			slug: whiteLabel.slug,
		})
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<StartonMenu
				open={menuIsOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				MenuListProps={{ sx: { padding: 0 } }}
				data-testid="user-menu"
			>
				<Link href={myProfileLink} data-testid="user-menu-my-profile">
					<StartonMenuItem>
						<StartonMenuItemTitle compact icon={<PersonOutlined />} title={t('common:common.myProfile')} />
					</StartonMenuItem>
				</Link>
				<StartonMenuItem>
					<StartonMenuItemTitle
						compact
						icon={<ForumOutlined />}
						title={t('common:support.menuItemName')}
						onClick={handleOpenSupport}
						data-testid="user-menu-support"
					/>
				</StartonMenuItem>
				<StartonMenuItem>
					<StartonMenuItemTitle
						compact
						icon={whiteLabel.colorMode === 'dark' ? <LightMode /> : <DarkMode />}
						title={t(
							`common:colorMode.${whiteLabel.colorMode === 'dark' ? 'switchLightMode' : 'switchDarkMode'}`,
						)}
						onClick={handleColorMode}
						data-testid="user-menu-support"
					/>
				</StartonMenuItem>
				<StartonMenuItem>
					<StartonMenuItemTitle
						compact
						icon={<LoginOutlined />}
						title={t('common:common.logOut')}
						onClick={handleLogout}
						data-testid="user-menu-logout"
					/>
				</StartonMenuItem>
			</StartonMenu>
			<ButtonStyled variant="text" onClick={openMenuOnClick} data-testid={'open-user-menu'} {...buttonProps}>
				<StartonAvatar>
					<Typography color="text.primary" variant="button">
						{avatarLetter}
					</Typography>
				</StartonAvatar>
			</ButtonStyled>
		</React.Fragment>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
StartonUserMenu.displayName = 'StartonUserMenu'

/*
| Developed by Starton
| Filename : WhiteLabelNewPresetFormFields.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Field, Form, FormikProps } from 'formik'
import { StartonButton, StartonTextField } from '@starton/react-ui'
import useTranslate from 'next-translate/useTranslation'
import { Box, Typography } from '@mui/material'
import { WhiteLabelNewPresetFormValues } from '@/components/white-labelling/drawer/views'
import { StartonSwitchField } from '@/components/common/starton-formik/StartonSwitchField'
import { useWhiteLabel, WhiteLabelConfiguration } from '@/components/white-labelling/context/whitelabel.context'
import { useDebounce } from '@/hooks/useDebounce'
import { StartonColorTextField } from '@/components/common/starton-formik/StartonColorTextField/StartonColorTextField'
import { ImageUploadField } from '@/components/common/fields/ImageUploadField'
import StartonUtils from '@/utils/starton.utils'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface WhiteLabelNewPresetFormFieldsProps {
	formikProps: FormikProps<WhiteLabelNewPresetFormValues>
	handleBack: VoidFunction
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const WhiteLabelNewPresetFormFields: React.FC<WhiteLabelNewPresetFormFieldsProps> = ({
	formikProps,
	handleBack,
}) => {
	const { t } = useTranslate()
	const whiteLabel = useWhiteLabel()
	const currentWhiteLabel = React.useRef<WhiteLabelConfiguration | null>(null)
	const debouncedFormikValues = useDebounce(formikProps.values, 200)

	// Save current white label configuration for reset form
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (currentWhiteLabel.current === null) {
			currentWhiteLabel.current = {
				slug: whiteLabel.slug,
				name: whiteLabel.name,
				logo: whiteLabel.logo,
				logoType: whiteLabel.logoType,
				colorMode: whiteLabel.colorMode,
				twitterPageUrl: whiteLabel.twitterPageUrl,
				featuredFlags: whiteLabel.featuredFlags,
				homeVideos: whiteLabel.homeVideos,
				theme: whiteLabel.theme,
				isStarton: whiteLabel.isStarton,
			}
		}
	}, [
		whiteLabel.featuredFlags,
		whiteLabel.homeVideos,
		whiteLabel.colorMode,
		whiteLabel.isStarton,
		whiteLabel.logo,
		whiteLabel.logoType,
		whiteLabel.name,
		whiteLabel.theme,
		whiteLabel.twitterPageUrl,
		whiteLabel.slug,
	])

	// Update white label configuration on form change
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (currentWhiteLabel.current === null) {
			return
		}

		whiteLabel.onUpdate(
			{
				slug: whiteLabel.slug,
				name: whiteLabel.name,
				logo:
					// @ts-ignore
					debouncedFormikValues?.logo instanceof File
						? URL.createObjectURL(debouncedFormikValues.logo)
						: currentWhiteLabel.current.logo,
				logoType:
					// @ts-ignore
					debouncedFormikValues?.logoType instanceof File
						? URL.createObjectURL(debouncedFormikValues.logoType)
						: currentWhiteLabel.current.logoType,
				isStarton: whiteLabel.name === 'Starton',
				colorMode: debouncedFormikValues.colorMode ? 'light' : 'dark',
				twitterPageUrl: debouncedFormikValues.twitterPageUrl,
				theme: {
					palette: {
						primary: {
							main: StartonUtils.ifValidHexOrWhite(debouncedFormikValues.theme.primary.main),
							dark: StartonUtils.ifValidHexOrWhite(debouncedFormikValues.theme.primary.dark),
							light: StartonUtils.ifValidHexOrWhite(debouncedFormikValues.theme.primary.light),
							hover: StartonUtils.ifValidHexOrWhite(debouncedFormikValues.theme.primary.hover),
							contrastText: StartonUtils.ifValidHexOrWhite(
								debouncedFormikValues.theme.primary.contrastText,
							),
						},
					},
				},
				featuredFlags: {
					poweredBy: debouncedFormikValues.featuredFlags.poweredBy,
					enableRelayer: debouncedFormikValues.featuredFlags.enableRelayer,
					enableListener: debouncedFormikValues.featuredFlags.enableListener,
					enablePlayground: debouncedFormikValues.featuredFlags.enablePlayground,
					enableRPCNode: debouncedFormikValues.featuredFlags.enableRPCNode,
				},
				homeVideos: {
					transactionManager: debouncedFormikValues.homeVideos?.transactionManager,
					smartContract: debouncedFormikValues.homeVideos?.smartContract,
					ipfs: debouncedFormikValues.homeVideos?.ipfs,
					monitor: debouncedFormikValues.homeVideos?.monitor,
				},
			},
			true,
		)
	}, [debouncedFormikValues, whiteLabel])

	// Methods
	// ----------------------------------------------------------------------------
	const handleBackAndReset = async () => {
		handleBack()
		formikProps.resetForm()

		if (currentWhiteLabel.current === null) {
			return
		}

		whiteLabel.onUpdate(currentWhiteLabel.current, true)
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<Form>
			<Box display={'flex'} flexDirection={'column'} gap={4}>
				<Field
					name={'name'}
					component={StartonTextField}
					label={t('common:whitelabel.drawer.new.form.name.label')}
					placeholder={t('common:whitelabel.drawer.new.form.name.placeholder')}
				/>

				<Box display={'flex'} flexDirection={'column'} width={'100%'}>
					<Typography variant={'body2'}>{t('common:whitelabel.drawer.new.form.logo.label')}</Typography>
					<ImageUploadField
						name={'logo'}
						helperText={t('common:whitelabel.drawer.new.form.logo.helperText')}
						dropzoneProps={{
							minSize: 1,
						}}
						imageDropzoneProps={{
							sx: {
								aspectRatio: '1/1',
							},
						}}
					/>
				</Box>

				<Box display={'flex'} flexDirection={'column'} width={'100%'}>
					<Typography variant={'body2'}>{t('common:whitelabel.drawer.new.form.logoType.label')}</Typography>
					<ImageUploadField
						name={'logoType'}
						helperText={t('common:whitelabel.drawer.new.form.logoType.helperText')}
						dropzoneProps={{
							minSize: 1,
						}}
						imageDropzoneProps={{
							sx: {
								aspectRatio: '3/1',
							},
						}}
					/>
				</Box>

				<Box display={'flex'} flexDirection={'column'} gap={1}>
					<Typography variant={'subtitle2'}>
						{t('common:whitelabel.drawer.new.form.colorMode.title')}
					</Typography>
					<StartonSwitchField
						name={'colorMode'}
						label={t('common:whitelabel.drawer.new.form.colorMode.label')}
					/>
				</Box>

				<Box>
					<Typography variant={'subtitle2'}>
						{t('common:whitelabel.drawer.new.form.featuredFlags.title')}
					</Typography>
					<StartonSwitchField
						name={'featuredFlags.poweredBy'}
						label={t('common:whitelabel.drawer.new.form.featuredFlags.options.poweredBy')}
					/>
					<StartonSwitchField
						name={'featuredFlags.enableRelayer'}
						label={t('common:whitelabel.drawer.new.form.featuredFlags.options.enableRelayer')}
					/>
					<StartonSwitchField
						name={'featuredFlags.enableListener'}
						label={t('common:whitelabel.drawer.new.form.featuredFlags.options.enableListener')}
					/>
					<StartonSwitchField
						name={'featuredFlags.enablePlayground'}
						label={t('common:whitelabel.drawer.new.form.featuredFlags.options.enablePlayground')}
					/>
					<StartonSwitchField
						name={'featuredFlags.enableRPCNode'}
						label={t('common:whitelabel.drawer.new.form.featuredFlags.options.enableRPCNode')}
					/>
				</Box>

				<Box display={'flex'} flexDirection={'column'} gap={1}>
					<Typography variant={'subtitle2'}>
						{t('common:whitelabel.drawer.new.form.twitterPageUrl.title')}
					</Typography>
					<Field
						name={'twitterPageUrl'}
						component={StartonTextField}
						placeholder={t('common:whitelabel.drawer.new.form.twitterPageUrl.placeholder')}
						multiline
						rows={4}
					/>
				</Box>

				<Box display={'flex'} flexDirection={'column'} gap={1}>
					<Typography variant={'subtitle2'}>
						{t('common:whitelabel.drawer.new.form.homeVideos.title')}
					</Typography>
					<Typography variant={'body2'} color={'text.secondary'}>
						{t('common:whitelabel.drawer.new.form.homeVideos.description')}
					</Typography>
					<Field
						name={'homeVideos.transactionManager'}
						component={StartonTextField}
						placeholder={t('common:whitelabel.drawer.new.form.homeVideos.transactionManager')}
					/>
					<Field
						name={'homeVideos.smartContract'}
						component={StartonTextField}
						placeholder={t('common:whitelabel.drawer.new.form.homeVideos.smartContract')}
					/>
					<Field
						name={'homeVideos.ipfs'}
						component={StartonTextField}
						placeholder={t('common:whitelabel.drawer.new.form.homeVideos.ipfs')}
					/>
					<Field
						name={'homeVideos.monitor'}
						component={StartonTextField}
						placeholder={t('common:whitelabel.drawer.new.form.homeVideos.monitor')}
					/>
				</Box>

				<Box display={'flex'} flexDirection={'column'} gap={1}>
					<Typography variant={'subtitle2'}>{t('common:whitelabel.drawer.new.form.theme.title')}</Typography>
					<Box display={'flex'} flexDirection={'column'} gap={1}>
						<Typography variant={'body1'}>
							{t('common:whitelabel.drawer.new.form.theme.primary.title')}
						</Typography>
						<Box display={'flex'} flexDirection={'column'} gap={1} paddingLeft={2}>
							<StartonColorTextField
								name={'theme.primary.main'}
								color={formikProps.values.theme.primary.main}
								label={t('common:whitelabel.drawer.new.form.theme.primary.options.main')}
							/>
							<StartonColorTextField
								name={'theme.primary.dark'}
								color={formikProps.values.theme.primary.dark}
								label={t('common:whitelabel.drawer.new.form.theme.primary.options.dark')}
							/>
							<StartonColorTextField
								name={'theme.primary.light'}
								color={formikProps.values.theme.primary.light}
								label={t('common:whitelabel.drawer.new.form.theme.primary.options.light')}
							/>
							<StartonColorTextField
								name={'theme.primary.hover'}
								color={formikProps.values.theme.primary.hover}
								label={t('common:whitelabel.drawer.new.form.theme.primary.options.hover')}
							/>
							<StartonColorTextField
								name={'theme.primary.contrastText'}
								color={formikProps.values.theme.primary.contrastText}
								label={t('common:whitelabel.drawer.new.form.theme.primary.options.contrastText')}
							/>
						</Box>
					</Box>
				</Box>

				<Field
					name={'apiKey'}
					type={'password'}
					component={StartonTextField}
					label={t('common:whitelabel.drawer.new.form.apiKey.label')}
					placeholder={t('common:whitelabel.drawer.new.form.apiKey.placeholder')}
					helperText={t('common:whitelabel.drawer.new.form.apiKey.helperText')}
				/>

				<Box display={'flex'} flexDirection={'column'} gap={1}>
					<StartonButton fullWidth variant={'contained'} size={'medium'} type={'submit'}>
						{t('common:whitelabel.drawer.new.form.submit.create')}
					</StartonButton>
					<StartonButton fullWidth variant={'outlined'} size={'medium'} onClick={handleBackAndReset}>
						{t('common:whitelabel.drawer.new.form.submit.back')}
					</StartonButton>
				</Box>
			</Box>
		</Form>
	)
}
